import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const Contactus = () => {
    return (
        <div>
            <body className="home-3">
            <Header />

    <div className="google-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3770.0372891591815!2d72.880696!3d19.10602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c86b7b8288eb%3A0xbfbfed65ce9f6faa!2sSeagull%20International!5e0!3m2!1sen!2sin!4v1729513619035!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>


    <section class="contact-info">
        <div class="container-fluid no-pad">
           <div class="contact-info-inner">
               <div class="row no-gutters">
                  <div class="col-md-4">
                      
                       <div class="email-info sin-cont-info d-flex align-items-center">
                          <div class="center-wrap">
                           <i class="flaticon-at"></i>
                           <h3>Email Us</h3>
                           <p>Mail:<a href="mailto:info@seagullindia.net"> info@seagullindia.net</a></p>
                           </div>
                           
                       </div>
                   </div>
                   <div class="col-md-4">
                        <div class="office-location sin-cont-info d-flex align-items-center">
                           <div class="center-wrap">
                            <i class="flaticon-map-pin-marked"></i>
                            <h3>Corporate Office</h3>
                            <p>4th Floor, Wellington Business Park <br></br>ll Ruby Coach Lane, Off Andheri  <br></br> Kurla Rd Marol, Andheri East, <br></br>Mumbai 400059</p>
                           </div> 
                        </div>
                    </div>
                   <div class="col-md-4">
                        <div class="call-us sin-cont-info d-flex align-items-center">
                            <div class="center-wrap">
                                <i class="flaticon-telephone-of-old-design"></i>
                                <h3>call Us</h3>
                                <p>Phone: <a href="tel:+912246162271">+91 22 4616 2271 / 72 / 73</a></p>
                            </div>
                        </div>
                   </div>
                
               </div>
           </div>
        </div>
    </section>


       <section className="contuct-bottom style-two">
           <div className="container">
               <div className="row justify-content-center">
                   <div className="col-xl-8">
                      <div className="con-bottom-inner">
                        <h3>Get In Touch</h3>
                            <form className="con-page-form" action="mail.php" id="contact-form" method="post">
                               <textarea name="message" placeholder="Message"></textarea>
                               <input type="text" name="name"  placeholder="Name *" className="mar-r" />
                               <input type="text" name="email" placeholder="Email *" />
                               <button  type="submit" >Submit</button>
                           </form>
                           <p className="form-messege"></p>
                      </div>
                       
                   </div>
               </div>
           </div>
       </section>
       
        <Footer />
        </body>
       
        </div>
      )
}

export default Contactus